.organization-dev {
	.status {
		margin-left: 8px;

		&-enabled {
			color: var(--color-success);
		}

		&-disabled {
			color: var(--color-error-text);
		}
	}
}

.organization-dev-modal {
	.connection-status {
		margin-bottom: 15px;
	}

	.device-property-section {
		padding: 12px 12px 0;
		margin-bottom: 15px;
		max-height: 230px;
		overflow-y: auto;
		border: 1px solid var(--color-border);
		border-radius: 6px;

		//捲軸底色
		&::-webkit-scrollbar-track {
			display: block;
		}
		//捲軸寬度
		&::-webkit-scrollbar {
			display: block;
		}
		//捲軸本體顏色
		&::-webkit-scrollbar-thumb {
			display: block;
		}

		&-title {
			font-size: 14px;
			font-weight: 700;
			color: var(--color-text);
			margin-bottom: 10px;
		}
	}
}
