.performance-awarded {
	.tool-bar {
		display: flex;
		justify-content: space-between;

		.left {
			display: flex;
			align-items: center;
			gap: 12px;
		}

		.right {
			display: flex;
			align-items: center;
			gap: 12px;
		}
	}

	.data-table {
		margin: 20px 0;
		min-height: 40vh;
	}
}
