.market-competition {
	.tool-bar {
		display: flex;
		justify-content: space-between;

		.left {
			display: flex;
			align-items: center;
			gap: 12px;
		}

		.right {
			display: flex;
			align-items: center;
			gap: 12px;
		}
	}

	.data-table {
		margin: 20px 0;
		min-height: 40vh;

		.table-action {
			&.disabled {
				svg {
					cursor: not-allowed;
					path {
						fill: var(--color-text-quaternary);
					}
				}
			}
		}
	}
}

.copy-modal-point {
	margin-top: 20px;
	flex-flow: unset;
	gap: 6px;

	.anticon-check-circle {
		color: var(--color-primary);
		font-size: 18px;
	}
}

.copy-date-part {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 0px 0px;
	grid-auto-flow: row;
	grid-template-areas:
		'copy-days copy-start'
		'copy-days copy-end';

	.copy-days {
		grid-area: copy-days;
		align-self: center;
		width: 90%;
	}

	.copy-start {
		grid-area: copy-start;
	}

	.copy-end {
		grid-area: copy-end;
	}
}
