.operating-device-modal {
	.data-table {
		margin: 20px 0;
		max-height: 73vh;

		.ant-table-tbody {
			.ant-tag {
				margin-inline-end: 0;
			}
		}
	}

	.device-modal-collapse {
		background-color: var(--color-bg-container);

		> .ant-collapse-item {
			> .ant-collapse-header {
				padding: 4px 12px;
				.ant-collapse-header-text {
					color: var(--color-primary);
					font-size: 14px;
					font-weight: 400;
					text-align: left;
				}
			}
			.ant-collapse-content {
				border-top: none;

				.ant-collapse-content-box {
					padding: 6px 16px 10px 16px;
				}
			}
		}

		.device-modal-detail {
			&-label {
				color: var(--color-text-quaternary);
				font-size: 12px;
				font-weight: 400;
			}

			&-text {
				color: var(--color-text-tertiary);
				font-size: 12px;
				font-weight: 400;
			}
		}
	}
}
