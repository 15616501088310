.market-price-info {
	.tool-bar {
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;

		.date-select {
			display: flex;
			align-items: center;
			gap: 10px;
		}

		.fullscreen-btn {
			display: flex;
			align-items: center;
			gap: 7px;
			cursor: pointer;

			font-size: 14px;
			color: var(--color-text-tertiary);
			svg path {
				fill: var(--color-svg-path);
			}
		}
	}

	.show-day-divider {
		color: var(--color-text-quaternary);
		margin: 0 0 16px 0;
	}
}
