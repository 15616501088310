.operating-overview {
	.tool-bar {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		margin-bottom: 20px;

		.left {
			display: flex;
			gap: 20px;
		}

		.right {
			display: flex;
			gap: 20px;
			margin-left: auto;
		}
	}

	.card-section {
		overflow-y: auto;
		max-height: 68vh;
	}
}
