.market-abandon {
	min-width: 326px;
	box-sizing: border-box;

	.tool-bar {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		margin-bottom: 40px;

		.left {
			display: flex;
			gap: 20px;
		}

		.right {
			display: flex;
			gap: 20px;
			margin-left: auto;
		}
	}

	.loading {
		width: 100%;
		height: 191px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.data-card {
		align-items: baseline;
	}
}
