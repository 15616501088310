.card-view {
	margin-bottom: 20px;
	border: 1px solid var(--color-border);

	.ant-card-body {
		padding: 12px;
	}

	&-info {
		margin: 0 4px 12px;
		align-items: baseline;
		position: relative;
		display: flex;
		align-items: start !important;

		.btn-group {
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			z-index: 2;

			.icon-btn {
				display: flex;
				border-radius: 50%;
				width: 36px;
				height: 36px;
				background-color: var(--color-bg-layout);
				cursor: pointer;
				align-items: center;
				justify-content: center;

				&:first-child {
					margin-right: 16px;
				}

				&:active {
					transition: all 5ms ease-in-out;
					background-color: darken(#f2f4f7, 10%);
				}
			}
		}
	}

	&-header {
		color: var(--color-text-tertiary);
		font-size: 14px;
		font-weight: 400;
		display: block;
	}

	&-title {
		font-size: 24px;
		font-weight: 700;
		line-height: 28px;
		margin-bottom: 10px;
		display: block;
	}

	&-subtitle {
		color: var(--color-text-quaternary);
		font-size: 14px;
		font-weight: 400;
		display: block;
	}

	&-value {
		color: var(--color-text);
		font-size: 18px;
		font-weight: 400;
		display: flex;
		margin-top: 8px;
		align-items: center;
	}

	&-tag {
		margin-top: 8px;
	}

	&-time-section {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		svg {
			margin: 8px 4px 0 4px;
		}
	}

	&-chart-details {
		background-color: var(--color-bg-layout);
		width: 100%;
	}

	&-chart {
		padding: 12px 14px 0;
		align-items: start !important;
	}

	.dot {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		margin-right: 8px;
		display: inline-flex;

		&-awarded {
			background: #72d126;
		}

		&-clipped {
			background: #ff7a00;
		}

		&-abandoned {
			background: #667085;
		}
	}
}
