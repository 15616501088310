.system-manage-user {
	.data-table {
		max-height: 69vh;
		.status-enable {
			color: var(--color-success);
		}

		.status-disable {
			color: var(--color-error-text);
		}
	}
}

.system-manage-user-modal {
	.ant-modal-body {
		.ant-form {
			.ant-form-item {
				&.confirmed-pwd-item {
					margin-bottom: 10px;
				}
			}

			.pwd-validate {
				margin-bottom: 20px;
				align-items: self-start;

				.pwd-checked,
				.pwd-unchecked,
				.pwd-error {
					display: flex;
					font-size: 12px;
					font-weight: 400;
					align-items: center;
				}

				.pwd-checked {
					color: var(--color-success);
				}

				.pwd-unchecked {
					color: var(--color-text-tertiary);
				}

				.pwd-error {
					color: var(--color-error-text);
				}

				svg {
					margin-right: 6px;
					width: 10px;
					height: 8px;
				}
			}
		}
	}
}
