.user-edit {
	.user-edit-form {
		width: 385px;

		.pwd-validate {
			margin-bottom: 20px;
			align-items: self-start;

			.pwd-checked,
			.pwd-unchecked,
			.pwd-error {
				display: flex;
				font-size: 12px;
				font-weight: 400;
				align-items: center;
			}

			.pwd-checked {
				color: var(--color-success);
			}

			.pwd-unchecked {
				color: var(--color-text-tertiary);
			}

			.pwd-error {
				color: var(--color-error-text);
			}

			svg {
				margin-right: 6px;
				width: 10px;
				height: 8px;
			}
		}
	}
	.user-edit-btn-cancel {
		margin-right: 12px;
	}
}
