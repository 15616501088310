#login-page {
	height: 100vh;
	background-image: url('../../assets/image/login-bg.png');
	background-position: center center;
	background-size: cover;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;

	.center-part {
		position: relative;
		z-index: 1;
		margin: auto;
		width: 280px;
		text-align: center;

		.center-title {
			margin-bottom: 40px;

			.title-text {
				margin-top: 40px;
				font-weight: 700;
				font-size: 24px;
				color: var(--color-primary);
			}

			.version-text {
				margin-top: 8px;
				font-size: 14px;
				font-weight: 400;
				color: #b0b4c0;
			}
		}

		.input-fields {
			.ant-input-affix-wrapper {
				color: #344054;
				background-color: #fff;
				border-color: #d0d5dd;
				.ant-input {
					color: #344054;
					background-color: #fff;
				}
			}

			button {
				font-size: 14px;
			}

			.error-text {
				z-index: 1;
				font-size: 12px;
				text-align: left;
				transform: translateY(-16px);
				margin-bottom: 8px;
				color: var(--color-error-text);
			}
		}
	}

	.footer-part {
		width: 290px;
		position: absolute;
		margin: 0 auto;
		left: 0;
		right: 0;
		bottom: 20px;
		font-size: 14px;
		font-weight: 400;
		color: #b0b4c0;

		.footer-links {
			display: flex;
			gap: 24px;
			justify-content: center;
			margin-bottom: 12px;
			color: #b0b4c0;

			a {
				&:link,
				&:visited,
				&:hover,
				&:active {
					color: #b0b4c0;
				}
			}
		}
	}
}
