.card-table-switch {
	margin-bottom: 25px;
	button {
		&:first-child {
			border-radius: calc(var(--gap) * var(--border-radius)) 0 0
				calc(var(--gap) * var(--border-radius));
		}
		&:last-child {
			border-radius: 0 calc(var(--gap) * var(--border-radius))
				calc(var(--gap) * var(--border-radius)) 0;
		}
	}

	.ant-btn {
		border: 1px solid var(--color-primary);
		color: var(--color-primary);
		background-color: var(--color-bg-container);
		height: 36px;

		&.selected {
			background: var(--color-primary);
			color: var(--color-always-white);
			box-shadow: none;

			svg path {
				fill: var(--color-always-white);
			}
		}
	}
}
