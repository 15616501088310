@font-face {
	font-family: 'Roboto';
	src: url('./assets/fonts/Roboto-Light.ttf') format('truetype');
	font-weight: 300;
}
@font-face {
	font-family: 'Roboto';
	src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
	font-weight: 400;
}
@font-face {
	font-family: 'Roboto';
	src: url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
	font-weight: 500;
}
@font-face {
	font-family: 'Roboto';
	src: url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
	font-weight: 700;
}
@font-face {
	font-family: 'NotoSansTC';
	src: url('./assets/fonts/NotoSansTC-Light.otf') format('truetype');
	font-weight: 300;
}
@font-face {
	font-family: 'NotoSansTC';
	src: url('./assets/fonts/NotoSansTC-Regular.otf') format('truetype');
	font-weight: 400;
}
@font-face {
	font-family: 'NotoSansTC';
	src: url('./assets/fonts/NotoSansTC-Medium.otf') format('truetype');
	font-weight: 500;
}
@font-face {
	font-family: 'NotoSansTC';
	src: url('./assets/fonts/NotoSansTC-Bold.otf') format('truetype');
	font-weight: 700;
}

body {
	--gap: 1px;
	padding: 0;
	margin: 0;
	font-family: 'NotoSansTC', 'Roboto';

	a {
		text-decoration: none;
	}

	.ant-layout {
		height: 100%;
	}

	.ant-btn {
		padding: 6px 16px;
		height: auto;

		&.ant-btn-primary {
			box-shadow: none;
		}

		&:not(:disabled):focus-visible {
			outline: none;
		}
	}

	.ant-row {
		align-items: center;
	}

	.ant-input-affix-wrapper {
		.ant-input-prefix {
			margin-inline-end: 10px;
		}
	}

	.ant-modal {
		&.ant-modal-confirm {
			.ant-modal-confirm-body {
				.ant-modal-confirm-title {
					color: #344054;
				}
				.ant-modal-confirm-content {
					color: #667085;
				}
			}
			.ant-modal-confirm-btns {
				.ant-btn-primary {
					box-shadow: none;
					background-color: var(--color-primary);
				}
			}
		}

		.ant-modal-close {
			top: 20px;
			svg {
				margin-top: 5px;
			}
		}
	}

	.show-day-divider {
		display: flex;
		gap: 10px;
		font-size: 12px;

		.ant-switch {
			min-width: 32px;
			width: 32px;
			height: 18px;
			border: 1px solid transparent;

			.ant-switch-handle {
				height: 12px;
				width: 12px;
			}

			&.ant-switch-checked {
				.ant-switch-handle {
					inset-inline-start: calc(100% - 15px);
				}
			}
		}
	}

	.chart-bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;

		.title-part {
			font-size: 16px;
			font-weight: 400;
			color: var(--color-text);
		}

		.switch-part {
			gap: 25px;
			display: flex;

			.switch-label {
				display: flex;
				align-items: center;
				gap: 12px;
				font-size: 12px;

				.switch-label-title {
					line-height: 22px;
					color: #b0b4c0;
				}

				> div {
					line-height: 22px;
					display: flex;
					align-items: center;
					gap: 4px;
					color: var(--color-text-quaternary);

					&.switch-on {
						color: var(--color-text);
					}

					.ant-switch {
						min-width: 32px;
						width: 32px;
						height: 18px;
						border: 1px solid transparent;

						.ant-switch-handle {
							height: 12px;
							width: 12px;
						}

						&.ant-switch-checked {
							.ant-switch-handle {
								inset-inline-start: calc(100% - 15px);
							}
						}
					}
				}
			}

			.switch-expand {
				display: flex;
				align-items: center;
				font-size: 14px;
				gap: 4px;
				cursor: pointer;
				color: #b0b4c0;
				svg path {
					fill: var(--color-svg-path);
				}
			}
		}
	}

	.chart {
		position: relative;
		margin-bottom: 20px;
		width: 100%;
		height: 300px;

		.highcharts-legend-item {
			.highcharts-point,
			.highcharts-graph {
				display: none;
			}
		}

		.hint-text {
			font-size: 12px;
			color: var(--color-chart-normal);
			position: absolute;
			right: 0;
			transform: translateY(-36px);
			pointer-events: none;
		}

		.fixed-no-data {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 150px;
			margin: auto;
			pointer-events: none; // 不讓這片 div 干預游標對圖表的 hover 行為
		}

		&.expand-chart {
			height: 600px;
			.spin-center {
				height: 600px;
			}
		}
	}

	.spin-center {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 300px;
	}

	.data-table {
		height: auto;
		min-height: auto;
		max-height: 62vh;
		overflow-y: auto;

		.ant-table-wrapper {
			.ant-table-empty {
				tbody {
					tr {
						td {
							padding: 50px 0 0;
						}
					}
				}
			}

			.ant-table-container {
				table {
					> thead {
						> tr {
							&:first-child {
								th {
									&:first-child,
									&:last-child {
										border-start-start-radius: 0;
										border-start-end-radius: 0;
									}
								}
							}
						}
					}
				}
			}

			.ant-table-thead {
				> tr {
					> th {
						background: var(--color-table-head-bg);
						border-bottom: none;
						&:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
							width: 0;
						}
					}

					.ant-table-cell {
						font-weight: 700;
					}
				}
			}

			.ant-table-tbody {
				> tr {
					&:nth-of-type(even) {
						background-color: var(--color-table-row-even);
					}

					&.ant-table-row {
						&:hover {
							> td {
								background: transparent;
							}
						}
					}

					> td {
						border-top: none;
						border-bottom: none;
						padding: 15px;
						max-width: 10px;

						.table-ellipsis {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							font-weight: 400;
						}

						&.ant-table-cell-row-hover {
							background: transparent;
						}

						.expand-icon {
							font-size: 13px;
							margin-inline-end: 12px;
						}

						.action-btn {
							svg {
								margin: 0 10px;
							}
						}

						svg {
							cursor: pointer;
						}
					}
				}

				.ant-table-row-level-1 {
					.ant-table-cell-with-append {
						.table-ellipsis {
							display: inline-flex;
							width: 60%;
						}
					}
				}
			}
		}
	}

	.data-card {
		height: auto;
		max-height: 61vh;
		overflow-y: auto;

		.ant-card {
			box-shadow: none !important;

			.ant-card-body {
				padding: 16px;

				.title {
					font-size: 16px;
					font-weight: 700;
					color: var(--color-text);
					margin-bottom: 16px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}

				.content {
					display: flex;
					align-items: center;
					font-size: 14px;
					font-weight: 400;

					.text {
						color: var(--color-text-quaternary);
					}

					.result {
						margin-left: 8px;
						color: var(--color-text-tertiary);
					}

					.ellipsis {
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}

				.highlight-content {
					margin-top: 16px;
					font-weight: 400;
					font-size: 14px;

					&-text {
						color: var(--color-text-quaternary);
					}

					&-result {
						font-size: 20px;
						font-weight: 700;
						color: var(--color-primary);
					}

					&-divider {
						border-inline-start: 1px solid var(--color-border);
						width: 1px;
						height: 43px;
						margin: 0 16px;
					}
				}
			}

			.ant-card-actions {
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				border-top: 1px solid var(--color-card-action-border);

				> li {
					margin: 7px 0;
				}

				.setting {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-evenly;
					font-size: 14px;
					font-weight: 400;
					color: var(--color-primary);
				}
			}
		}

		.ant-card-bordered {
			border: 1px solid var(--color-border);
		}
	}

	.required-field {
		color: var(--color-error-text);
	}

	.pagination-bar {
		display: flex;
		margin-top: 25px;

		.right {
			display: flex;
			margin-left: auto;
			align-items: center;
			gap: 16px;
		}
	}

	.ant-pagination {
		.ant-pagination-total-text {
			float: left;
			margin-inline-end: 20px;
		}

		.ant-pagination-prev,
		.ant-pagination-next {
			&.ant-pagination-disabled {
				.ant-pagination-item-link {
					border: 1px solid var(--color-text-quaternary);
				}
			}
			.ant-pagination-item-link {
				border: 1px solid var(--color-text);
			}
		}

		.ant-pagination-options {
			float: left;
			margin-inline-start: 0;
			margin-inline-end: 10px;
		}
	}

	.form-modal {
		.ant-modal-close {
			top: 24px;
			svg {
				margin-top: 5px;
			}
		}

		.ant-modal-title {
			margin-bottom: 20px;
			font-size: 20px;
			font-weight: 700;
		}

		.ant-modal-body {
			width: 100%;

			.ant-form {
				.ant-form-item {
					margin-bottom: 22px;
					font-weight: 400;

					.ant-row {
						align-items: flex-start;
					}

					&.label-multi-line {
						.ant-form-item-label {
							> label {
								align-items: baseline;
								line-height: 1.1;
							}
						}
					}
				}
			}

			.time-area {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				.time-info {
					width: 100%;
					color: var(--color-text-quaternary);
					font-size: 14px;
					font-weight: 400;
					line-height: 28px;
				}
			}

			.submit-btn {
				margin-top: 20px;
				float: right;
				background-color: var(--color-primary);
				font-size: 14px;
			}
		}
	}

	.custom-confirm-modal {
		width: 100%;

		.header {
			display: flex;
			align-items: center;

			.icon {
				margin-right: 18px;
			}

			.title {
				font-size: 16px;
				font-weight: 700;
			}
		}

		.context {
			width: 100%;
			margin: 8px 0 0 38px;
			display: flex;
			flex-wrap: wrap;

			.text {
				width: 100%;
				font-size: 14px;
				font-weight: 400;
				color: #667085;
			}
		}

		.ant-btn-default {
			border: 1px solid var(--color-primary);
			color: var(--color-primary);

			&:hover,
			&:active {
				color: var(--color-primary);
				border-color: var(--color-primary);
			}
		}
	}

	.ant-card-head-title {
		font-size: 20px;
		font-weight: 700;
	}

	.ant-picker-dropdown {
		.ant-picker-today-btn {
			color: var(--color-success);
			&:hover,
			&:active {
				color: var(--color-success);
			}
		}
	}

	.ant-spin-blur {
		.no-data {
			opacity: 0;
		}
	}
}

* {
	box-sizing: border-box;
}

::-webkit-scrollbar {
	display: none;
	width: 4px;
}

::-webkit-scrollbar-button {
	background: transparent;
	border-radius: 4px;
}

::-webkit-scrollbar-track-piece {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background-color: rgba(135, 135, 135, 0.2);
}

::-webkit-scrollbar-track {
	box-shadow: none;
}
