#main-layout {
	height: 100vh;

	.header {
		position: sticky;
		top: 0;
		width: 100vw;
		z-index: 2;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 12px 20px 12px 16px;
		background: var(--color-bg-container);
		border-bottom: 1px solid var(--color-border);

		div,
		a {
			display: flex;
			align-items: center;
		}

		.header-title {
			margin-left: 12px;
			font-size: 20px;
			font-weight: 700;
			color: var(--color-primary);
		}

		.user-logo {
			cursor: pointer;
		}
	}

	.sidebar {
		height: calc(100vh - 85px);
		overflow-x: hidden;
		overflow-y: auto;
		margin-top: 16px;
		margin-left: 10px;
		width: 215px !important;
		flex: 0 0 215px !important;
		max-width: 215px !important;
		min-width: 215px !important;
		background: var(--color-bg-layout);

		ul {
			border: none;
			background: var(--color-bg-layout);

			.ant-menu-item,
			.ant-menu-submenu-title {
				border-radius: 0;
				margin-inline: 0;
			}

			.ant-menu-submenu-title {
				.ant-menu-title-content {
					margin-inline-start: 16px;
					color: var(--color-text);
				}
			}

			.ant-menu-item {
				.ant-menu-title-content {
					color: var(--color-text-tertiary);
				}
			}

			.ant-menu-item-selected {
				.ant-menu-title-content {
					color: var(--color-text);
				}
			}

			.ant-menu-item-selected::after {
				content: none;
			}

			li > ul > li::before {
				content: '';
				padding-left: 14px;
			}

			.ant-menu-submenu-arrow {
				color: var(--color-menu-arrow);
			}
		}
	}

	.main-content {
		padding: 15px 10px;
		overflow: auto;

		> .ant-card {
			width: 100%;
			box-shadow: 0px 0px 12px 0px #28307133;
			border-radius: 12px;

			.ant-card-head-title {
				color: var(--color-primary);
			}

			.ant-card-extra {
				color: var(--color-chart-normal);
			}

			&.card-fullscreen {
				.ant-card-head {
					display: none;
				}
			}

			> .ant-card-body {
				padding: 28px;
			}
		}

		.back-top-btn {
			width: 60px;
			height: 60px;
			border: 2px solid var(--color-back-top-border);
			inset-block-end: 55px;
			border-radius: 50%;
			// antd FloatButton.BackTop 的 description 仅当 shape 属性为 square 时支持，所以這邊手動變成圓形
		}
	}

	.footer {
		font-size: 14px;
		color: var(--color-text-quaternary);
		padding: 14px 0;
	}
}

.header-dropdown {
	.header-dropdown-item {
		line-height: 22px;
		font-size: 14px;
		padding: 5px 4px;
		display: block;
		color: var(--color-text);
		margin-top: 12px;

		&.toggle-theme {
			display: flex;
			align-items: center;
			cursor: pointer;
			gap: 10px;
		}

		&.pointer {
			cursor: pointer;
		}

		&:first-child {
			margin-top: 0;
		}
	}
}

// HIDE
#main-layout {
	&.hide {
		padding-bottom: 0;
		background: var(--color-bg-container);

		.header {
			display: none;
		}

		.sidebar {
			display: none;
		}

		.main-content {
			margin: 0;
			padding: 0;
			width: 100vw;
			height: 100vh;
			background: var(--color-bg-container);

			& > div {
				border-radius: 0;
				box-shadow: none;
			}
		}

		.footer {
			display: none;
		}
	}
}
