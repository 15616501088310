.operating-device {
	.update-info {
		> div,
		> span {
			display: inline-block;
		}
		> div {
			margin-right: 12px;
		}
		> span {
			color: var(--color-primary);
			background: transparent;
			border-color: var(--color-primary);
		}
	}

	.tool-bar {
		display: flex;
		justify-content: space-between;

		.left {
			display: flex;
			align-items: center;
			gap: 12px;
		}
	}

	.data-table {
		margin: 20px 0;
		.ant-table-tbody {
			.ant-tag {
				margin-inline-end: 0;
			}
		}
	}
}
