.operating-service {
	.api-is-loading {
		position: absolute;
		top: 60px;
		right: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: auto;
		margin: auto;
		z-index: 10;
		background-color: inherit;
		cursor: not-allowed;
	}

	.update-info {
		> div,
		> span {
			display: inline-block;
		}
		> div {
			margin-right: 12px;
		}
		> span {
			color: var(--color-primary);
			background: transparent;
			border-color: var(--color-primary);
		}
	}

	.dropdown-part {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;

		.dropdown-group {
			display: flex;
			gap: 12px;
		}

		.fullscreen-btn {
			display: flex;
			align-items: center;
			gap: 7px;
			cursor: pointer;

			font-size: 14px;
			color: var(--color-text-tertiary);

			svg path {
				fill: var(--color-svg-path);
			}
		}
	}

	.date-select-part {
		background-color: var(--color-date-select-bar-bg);
		border-radius: calc(var(--gap) * var(--border-radius));
		padding: 20px 25px;
		display: flex;
		margin-bottom: 20px;

		.type-select {
			min-width: 155px;

			.current-month-date {
				font-size: 20px;
				font-weight: 700;
				color: var(--color-always-white);
				margin-bottom: 12px;
			}

			.type-toggle {
				button {
					&:first-child {
						border-radius: calc(var(--gap) * var(--border-radius)) 0 0
							calc(var(--gap) * var(--border-radius));
					}
					&:last-child {
						border-radius: 0 calc(var(--gap) * var(--border-radius))
							calc(var(--gap) * var(--border-radius)) 0;
					}
				}

				button {
					border: 1px solid var(--color-always-white);
					color: var(--color-always-white);
					background-color: var(--color-date-select-bar-bg);
					padding: 8px 16px;

					&.selected {
						background: var(--color-always-white);
						color: var(--color-date-select-bar-bg);
						box-shadow: none;
					}
				}
			}
		}

		.date-select {
			width: 100%;
			position: relative;

			.type-week {
				margin: auto 40px auto 24px;
				display: flex;
				align-items: center;
				height: 100%;

				.weekday {
					cursor: pointer;
					flex-grow: 1;
					flex-basis: 0;

					.content {
						transition: 0.2s background;
						border-radius: 6px;
						display: inline-block;
						text-align: center;
						width: calc(100% - 1px);
						padding: 10px 0;

						.title {
							font-size: 14px;
							color: #cce198;
						}

						.date {
							color: var(--color-always-white);
							font-size: 24px;
						}
					}

					&:hover {
						.content {
							background: rgba(255, 255, 255, 0.1);
							width: calc(100% - 1px);
						}
					}

					&.selected {
						.content {
							transform: translateX(-1px);
							background: var(--color-always-white);
							border-radius: 6px;
							width: 100%;

							.title {
								color: var(--color-text-quaternary);
							}

							.date {
								color: var(--color-date-select-bar-bg);
							}
						}

						&::after {
							display: none;
						}
					}

					&.disabled {
						.content {
							.date {
								opacity: 0.6;
							}
						}
						&:hover {
							.content {
								background: inherit;
								cursor: not-allowed;
							}
						}
					}

					&::after {
						content: '';
						width: 1px;
						height: 40px;
						background: var(--color-primary-bg);
						display: inline-block;
						vertical-align: sub;
						margin: 0 0;
					}
				}
			}

			.type-specify {
				margin-left: 24px;

				.ant-picker {
					color: #344054;
					background: #fff;
					border: 1px solid #d0d5dd;

					.ant-picker-input > input {
						color: #344054;
						background: #fff;
					}

					.ant-picker-clear {
						color: #b0b4c0;
						background: #fff;
					}
				}

				.show-day-divider {
					color: var(--color-always-white);
					margin: 16px 0 0 0;

					.ant-switch {
						min-width: 32px;
						width: 32px;
						height: 18px;
						border: 1px solid var(--color-always-white);

						.ant-switch-handle {
							height: 12px;
							width: 12px;
						}

						&.ant-switch-checked {
							.ant-switch-handle {
								inset-inline-start: calc(100% - 15px);
							}
						}
					}
				}
			}
		}

		.export-btn {
			max-width: 60px;
			display: flex;
			align-items: center;

			button {
				background-color: var(--color-always-white);
				border-radius: calc(var(--gap) * var(--border-radius));
				border-color: transparent;
				color: var(--color-date-select-bar-bg);
				width: 60px;
				padding: 0 0;

				&:disabled {
					opacity: 0.6;
				}
			}
		}
	}
}

.txg-group-dropdown {
	.group {
		color: var(--color-text-quaternary);
		font-weight: 700;
	}

	.item > div {
		padding-left: 12px;
	}
}

.expand-field {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	color: var(--color-primary);
	font-size: 12px;
	cursor: pointer;
}

.command-history {
	margin-top: 16px;
	.ant-card-body {
		max-height: 60vh;
		overflow-y: auto;
		overflow-x: hidden;
	}

	&.hide {
		display: none;
	}

	.ant-timeline {
		.ant-timeline-item-head {
			border: 2px solid transparent;
		}

		.ant-timeline-item-last {
			padding-bottom: 0;
			> .ant-timeline-item-content {
				min-height: unset;
			}
		}

		&.ant-timeline-label {
			.ant-timeline-item-right .ant-timeline-item-label {
				inset-inline-start: calc(50% + 22px);
			}
			.ant-timeline-item-right .ant-timeline-item-content,
			.ant-timeline-item-label {
				width: calc(50% - 20px);
			}
		}

		.ant-timeline-item-tail {
			border-inline-start: 2px solid var(--color-border);
		}
	}
}
