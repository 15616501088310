.no-data {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;

	> div {
		margin: 20px 0 0;
		color: var(--color-date-select-bar-bg);
	}
}
