.not-found {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	> div {
		margin: 20px 0;
	}
}
