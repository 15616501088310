.market-abandon {
	.ant-card {
		.setting-group {
			display: flex;
			flex-wrap: wrap;
			gap: 16px;

			.date-picker {
				width: 100%;
			}
		}

		.setting {
			&-enable {
				color: var(--color-primary);
				width: 100%;
			}

			&-disable {
				color: var(--color-text-quaternary);
				cursor: not-allowed;
				width: 100%;
			}

			&-cancel {
				color: var(--color-text-tertiary);
				width: 40%;
			}

			.action-row {
				width: 100%;
				display: flex;
				justify-content: space-evenly;
				align-items: center;

				.vertical-divider {
					width: 1px;
					height: 20px;
					background-color: var(--color-border-secondary);
				}

				.setting-enable {
					width: 40%;
				}
			}
		}
	}
}

.confirm-abandon-modal {
	width: 100%;

	.header {
		display: flex;
		align-items: center;

		.icon {
			margin-right: 18px;
		}

		.title {
			font-size: 16px;
			font-weight: 700;
		}
	}

	.context {
		width: 100%;
		margin: 8px 0 0 38px;
		display: flex;
		flex-wrap: wrap;
		gap: 8px;

		.text {
			width: 100%;
			font-size: 14px;
			font-weight: 400;
			color: #667085;

			&:last-child {
				margin-top: 10px;
			}
		}
	}

	.ant-btn-default {
		border: 1px solid var(--color-primary);
		color: var(--color-primary);

		&:hover,
		&:active {
			color: var(--color-primary);
			border-color: var(--color-primary);
		}
	}
}
