.organization-structure {
	.tool-bar {
		margin-bottom: 28px;
	}

	.content {
		align-items: flex-start;

		.tree-part {
			height: auto;
			max-height: 68vh;
			overflow: auto;
		}

		.description-part {
			width: 100%;

			.ant-descriptions {
				.ant-descriptions-view {
					border-top: 1px solid var(--color-border);
					border-left: 1px solid var(--color-border);
					border-bottom: 1px solid var(--color-border);
				}

				.ant-descriptions-header {
					.ant-descriptions-title {
						font-weight: 700;
					}
				}

				th {
					background-color: var(--color-table-row-even);
					color: var(--color-text);
					width: 25%;
					border-inline-end: 1px solid var(--color-border);
				}

				td {
					width: 25%;
					font-weight: 400;
					border-inline-end: 1px solid var(--color-border);
				}

				.ant-descriptions-row {
					border-bottom: 1px solid var(--color-border);

					&:first-child {
						border-top-left-radius: calc(var(--gap) * var(--border-radius));
						th {
							&:first-child {
								border-top-left-radius: calc(var(--gap) * var(--border-radius));
							}
						}
					}

					&:last-of-type {
						border-bottom: none;
						border-right: 1px solid var(--color-border);

						th {
							display: none;
						}
						td {
							color: var(--color-text-quaternary);
							width: 100%;
							border-inline-end: none;
						}
					}
				}
			}
		}

		.spinner {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			height: 40vh;
		}
	}
}
