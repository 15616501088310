.performance-implement {
	.chart {
		margin-top: 30px;
	}

	.tool-bar {
		display: flex;
		justify-content: space-between;

		.left {
			display: flex;
			align-items: center;
			gap: 12px;
		}

		.right {
			display: flex;
			align-items: center;
			gap: 12px;
		}
	}

	.data-table {
		margin: 25px 0;
	}
}
