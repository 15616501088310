.custom-number-input {
	width: 50%;

	.ant-input-number-group-addon {
		background-color: var(--color-bg-container);
		padding: 0 6px;

		.ant-btn {
			padding: 0;
			display: flex;
			justify-content: center;
			border-radius: 0;
			&:active,
			&:hover {
				background-color: var(--color-bg-container);
			}

			svg path {
				fill: var(--color-text);
			}
		}
	}

	.ant-input-number {
		min-width: 45px;

		.ant-input-number-input {
			height: 35px;
			text-align: center;
		}
	}
}
